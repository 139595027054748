// Imports
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
	faAddressBook,
	faBullhorn as faBullhornDuotone,
	faCalendars as faCalendarsDuotone,
	faCubes,
	faFiles as faFilesDuotone,
	faImages as faImagesDuotone,
} from '@fortawesome/pro-duotone-svg-icons';
import {
	faArrowUpFromBracket,
	faAt,
	faBan,
	faBullhorn as faBullhornSolid,
	faCalendars as faCalendarsSolid,
	faChartSimple,
	faClock,
	faFiles as faFilesSolid,
	faFolder,
	faHouseChimney,
	faHouseCircleCheck,
	faImages as faImagesSolid,
	faInbox,
	faInfinity,
	faLock,
	faMagnifyingGlass,
	faMessageCheck,
	faPaperclip,
	faPaw,
	faRepeat,
	faShieldCheck,
	faSparkles,
	faThumbsUp,
	faUserCheck,
	faUsers,
} from '@fortawesome/pro-solid-svg-icons';
import TabImage from './tab-image';


// Types
export type Identifiers = 'DOCUMENTS' | 'EMAILS_AND_TEXT' | 'CALENDARS' | 'FORMS' | 'GALLERIES' | 'DIRECTORIES';

interface Button {
	icon: IconDefinition;
	text: string;
}

interface Content {
	title: {
		prefix: string;
		emphasis: string;
	};
	description: string;
	features: {
		icon: IconDefinition;
		text: string;
	}[];
	image: JSX.Element;
}

interface Tab {
	identifier: Identifiers;
	button: Button;
	content: Content;
}


// Content for feature tabs
const tabs: Tab[] = [
	{
		identifier: 'DOCUMENTS',
		button: { icon: faFilesDuotone, text: 'Documents' },
		content: {
			title: {
				prefix: 'Your community’s documents',
				emphasis: 'Organized, accessible, & secure',
			},
			description:
				'Store meeting minutes, financials, welcome packets, and more. Upload your files in bulk and decide who has access to each.',
			features: [
				{
					icon: faShieldCheck,
					text: 'Restrict access to documents',
				},
				{
					icon: faFilesSolid,
					text: 'Upload multiple files at once',
				},
				{
					icon: faFolder,
					text: 'Organize documents into folders',
				},
				{
					icon: faInfinity,
					text: 'Unlimited storage space',
				},
				{
					icon: faUsers,
					text: 'Easy access for residents',
				},
			],
			image: <TabImage name='documents' />,
		},
	},
	{
		identifier: 'EMAILS_AND_TEXT',
		button: { icon: faBullhornDuotone, text: 'Emails & texts' },
		content: {
			title: {
				prefix: 'Get the word out',
				emphasis: 'Fast emails & text messages',
			},
			description:
				'Whether you’re sending announcements to a few dozen residents or a few thousand, our platform delivers communications rapidly and reliably.',
			features: [
				{
					icon: faThumbsUp,
					text: 'Rock solid deliverability',
				},
				{
					icon: faUserCheck,
					text: 'Full control over the recipients',
				},
				{
					icon: faClock,
					text: 'Schedule for the future',
				},
				{
					icon: faPaperclip,
					text: 'Attach files to emails',
				},
				{
					icon: faChartSimple,
					text: 'Track opens, clicks, and errors',
				},
			],
			image: <TabImage name='emails-and-texts' />,
		},
	},
	{
		identifier: 'CALENDARS',
		button: { icon: faCalendarsDuotone, text: 'Calendars' },
		content: {
			title: {
				prefix: 'Bring residents together',
				emphasis: 'Highlight upcoming events',
			},
			description:
				'Create calendar events to showcase what’s going on in your community. Post meetings, gatherings, activities, and more to keep your residents in the know.',
			features: [
				{
					icon: faBullhornSolid,
					text: 'Announce events via email',
				},
				{
					icon: faRepeat,
					text: 'Powerful repeating options',
				},
				{
					icon: faHouseCircleCheck,
					text: 'Comprehensive RSVP functionality',
				},
				{
					icon: faMessageCheck,
					text: 'Automatic inclusion in weekly emails',
				},
				{
					icon: faCalendarsSolid,
					text: 'Organize using separate calendars',
				},
			],
			image: <TabImage name='calendars' />,
		},
	},
	{
		identifier: 'FORMS',
		button: { icon: faCubes, text: 'Forms' },
		content: {
			title: {
				prefix: 'Online form submissions',
				emphasis: 'Easy, customizable, & secure',
			},
			description:
				'Build contact forms to collect information from residents and visitors alike. Replace paper forms with online forms for a more streamlined experience.',
			features: [
				{
					icon: faBan,
					text: 'Strong spam protection',
				},
				{
					icon: faFilesSolid,
					text: 'Collect file uploads',
				},
				{
					icon: faShieldCheck,
					text: 'Restrict access to forms',
				},
				{
					icon: faInfinity,
					text: 'Unlimited submission storage',
				},
				{
					icon: faInbox,
					text: 'Flexible recipient options',
				},
			],
			image: <TabImage name='forms' />,
		},
	},
	{
		identifier: 'GALLERIES',
		button: { icon: faImagesDuotone, text: 'Galleries' },
		content: {
			title: {
				prefix: 'Bring your community to life',
				emphasis: 'Showcase photos with galleries',
			},
			description:
				'Photo galleries are great for remembering gatherings and highlighting community amenities. Collaborate with residents by letting them contribute their own photos.',
			features: [
				{
					icon: faArrowUpFromBracket,
					text: 'Optionally let residents upload photos',
				},
				{
					icon: faImagesSolid,
					text: 'Upload multiple files at once',
				},
				{
					icon: faSparkles,
					text: 'Photos stored in high quality',
				},
				{
					icon: faInfinity,
					text: 'Unlimited storage space',
				},
				{
					icon: faShieldCheck,
					text: 'Restrict access to photos',
				},
			],
			image: <TabImage name='galleries' />,
		},
	},
	{
		identifier: 'DIRECTORIES',
		button: { icon: faAddressBook, text: 'Directories' },
		content: {
			title: {
				prefix: 'Connect your people',
				emphasis: 'Directories for residents & pets',
			},
			description:
				'Help families get to know their neighbors through secure directories. Plus, make returning lost pets easier with our dedicated pet directory.',
			features: [
				{
					icon: faLock,
					text: 'Secure & private by design',
				},
				{
					icon: faPaw,
					text: 'Pets get to be included',
				},
				{
					icon: faAt,
					text: 'Feature social profiles',
				},
				{
					icon: faMagnifyingGlass,
					text: 'Searchable',
				},
				{
					icon: faHouseChimney,
					text: 'Organized by household',
				},
			],
			image: <TabImage name='directories' />,
		},
	},
];

export default tabs;
