// Imports
import styled from 'styled-components/macro';
import LoadingImage from '../../../resources/loading-image';


// Styled components
const Wrapper = styled.div`
	min-width: 31.25rem;
	aspect-ratio: 500 / 469;
	line-height: 0;
	
	@media (max-width: 70rem) {
		min-width: 25rem;
	}
	
	@media (max-width: 60rem) {
		min-width: 20rem;
	}
	
	@media (max-width: 48rem) {
		min-width: 85vw;
		width: 85vw;
		max-width: 31.25rem;
	}
`;

const Image = styled(LoadingImage)`
	width: 100%;
	height: auto;
	border-radius: 2%;
`;


// Define the accepted props
interface Props {
	name: string;
}


// Function component
const TabImage: React.FC<Props> = ({ name }) => (
	<Wrapper>
		<Image width='1000' height='938' src={`/images/feature-tabs/${name}.webp`} alt={`“${name}” feature example`} />
	</Wrapper>
);

export default TabImage;
