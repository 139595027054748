// Imports
import styled from 'styled-components/macro';
import BannerWithList from '../../../resources/banner-with-list';
import Button from '../../../resources/button';
import items from './items';


// Styled components
const ExistingWebsite = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.9375rem;
	
	text-align: center;
	font-size: 1.5rem;
	line-height: 1;
	
	@media (max-width: 48rem) {
		font-size: 1.25rem;
	}
`;

const HyphenText = styled.span`
	white-space: nowrap;
`;


// Function component
const GettingStarted: React.FC = () => (
	<BannerWithList items={items} heading='Getting started couldn’t be easier' ordered>
		<ExistingWebsite>
			<p>
				Or if you have an existing website, let us migrate it for <HyphenText>you—for</HyphenText> free!
			</p>
			
			<Button
				backgroundColor='hsl(0, 0%, 100%)'
				borderColor='hsl(36, 56%, 69%)'
				fontColor='hsl(206, 25%, 20%)'
				href={`https://start.${process.env.REACT_APP__ENVIRONMENT_HOSTNAME}/?migrate=true`}
				rel='noopener'
				displayArrowIndicator
			>
				Migrate an existing website
			</Button>
		</ExistingWebsite>
	</BannerWithList>
);

export default GettingStarted;
